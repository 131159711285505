const fields = {
  inputs: {
    address: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "address",
        placeholder: "Название улицы",
        label: "Название улицы",
        type: "text",
        always_type: "text",
      },
    },
    street_index: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "street_index",
        placeholder: "Индекс улицы",
        label: "Индекс улицы",
        type: "text",
        always_type: "text",
        maska: {
          mask: "HHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
      },
    },
    delivery_zone: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "delivery_zone",
        placeholder: "зона доставки",
        label: "зона доставки",
        type: "text",
        always_type: "text",
        disabled: false,
        maska: {
          mask: "HHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
      },
    },
    affilate: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "affilate",
        placeholder: "Филиал",
        label: "Филиал",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    mileage: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "mileage",
        placeholder: "Расстояние",
        label: "Расстояние",
        type: "text",
        always_type: "text",
        maska: {
          mask: "HHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
        hidden: false,
      },
    },
  },
  radios: {
    affilate: {
      affilate_1: {
        value: [],
        val: "null",
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "affilate_1",
          label: "Оренб. тракт",
        },
        errorBag: [],
        validate: {},
      },
      affilate_2: {
        value: [],
        val: "32",
        isDirty: false,
        noValidate: true,
        attrs: {
          id: "affilate_2",
          label: "Дербышки",
        },
        errorBag: [],
        validate: {},
      },
    },
  },
};

export { fields };
