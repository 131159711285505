<template>
  <section class="addresses">
    <div class="addresses__controls">
      <button
        class="addresses__delete addresses__control"
        @click="addressController('deleteAddress')"
      >
        Удалить
      </button>
      <button
        class="addresses__append addresses__control"
        @click="addressController('appendAddress')"
      >
        Добавить
      </button>
      <button
        class="addresses__append addresses__control"
        @click="addressController('changeAddress')"
      >
        Редактировать
      </button>
    </div>
    <div class="addresses__list">
      <v-input
        :attrs="street_search.attrs"
        :errorBag="street_search.errorBag"
        v-model="street_search.value"
        class="addresses__dialog-input"
        style="width: 100%"
        :ref="street_search.attrs.id"
        @focusout="filtreAddresses"
      ></v-input>
      <v-radio
        class="addresses__item"
        :value="address.attrs.label"
        :attrs="address.attrs"
        v-model="active_address"
        v-for="(address, idx) in addresses"
        :key="idx"
      >
        <!-- <template v-slot:append>
          <img
            :src="promo.image"
            alt=""
            class="addresses__item-image"
            :ref="promo.value"
          />
        </template> -->
      </v-radio>
    </div>
    <v-dialog
      class="addresses__dialog"
      :data="addresses_dialog"
      @adminSave="addressController('setAddress')"
      @adminDelete="addressController('deleteAddress')"
      @closeDialog="closeDialog"
    >
      <form class="addresses__dialog-form">
        <v-input
          v-for="(field, idx) in address_form.fields.inputs"
          :key="idx"
          class="addresses__dialog-input"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          :ref="field.attrs.id"
        ></v-input>
        <v-radio
          v-for="(radio, idx) in address_form.fields.radios.affilate"
          :key="idx"
          class="folders__dialog-folder"
          :value="radio.val"
          :attrs="radio.attrs"
          v-model="address_form.fields.inputs.affilate.value"
        >
        </v-radio>
      </form>
    </v-dialog>
    <v-dialog
      class="addresses__dialog"
      :data="addresses_dialog_append"
      @adminSave="addressController('appendAddress')"
      @closeDialog="closeDialog"
    >
      <form class="addresses__dialog-form">
        <v-input
          v-for="(field, idx) in address_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="addresses__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/addresses_dialog";
export default {
  name: "AddressesViews",
  data() {
    return {
      active_address: "",
      clone_address_form: {},
      address_form: {
        fields,
      },
      refresh_form: {},
      street_search: {
        value: "",
        errorBag: [],
        isDirty: false,
        noValidate: false,
        attrs: {
          id: "street_name",
          placeholder: "Поиск по названию улицы",
          label: "Поиск по названию улицы",
          type: "text",
          always_type: "text",
        },
      },
      addresses_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление адресом",
      },
      addresses_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить адрес",
      },
      addresses: [],
    };
  },
  methods: {
    ...mapActions([
      "GET_ADMIN_ADDRESSES",
      "GLOBAL_NOTIFICATIONS",
      "DELETE_ADDRESS",
      "CHANGE_ADDRESS",
      "APPEND_ADDRESS",
    ]),
    async getAddresses() {
      return await this.GET_ADMIN_ADDRESSES();
    },
    // addressController(action, ...params) {
    //   let settings = params?.[0];
    //   console.log(settings);
    //   console.log(action);
    // },
    async addressController(action, ...params) {
      let settings = params?.[0];
      if (action == "appendAddress") {
        try {
          if (!this.addresses_dialog_append.options.visible) this.refreshForm();
          if (this.addresses_dialog_append.options.visible) {
            let valid = await this.addressValidation();
            if (valid)
              this.APPEND_ADDRESS(this.address_form.fields.inputs).then(
                (res) => {
                  this.GLOBAL_NOTIFICATIONS(res);
                  if (res?.status == 200) {
                    this.refreshForm("addresses_dialog_append");
                  }
                  setTimeout(async () => {
                    await this.GET_ADMIN_ADDRESSES();
                  }, 500);
                }
              );
          } else this.openDialog(this.addresses_dialog_append);
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setAddress") {
        try {
          let valid = await this.addressValidation();
          if (valid) {
            let newForm = this.address_form.fields.inputs;
            let oldForm = this.clone_address_form.fields.inputs;
            await this.CHANGE_ADDRESS({ newForm, oldForm }).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              if (res?.status == 200) {
                this.refreshForm();
              }
              setTimeout(async () => {
                await this.GET_ADMIN_ADDRESSES();
              }, 500);
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deleteAddress") {
        try {
          await this.DELETE_ADDRESS({ promotion: this.activeAddress }).then(
            (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              setTimeout(async () => {
                await this.GET_ADMIN_PROMOTIONS();
                this.active_promo = this.ADMIN_PROMOTIONS?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changeAddress") {
        try {
          if (this.activeAddress == "") {
            throw {
              title: "Адрес не выбран",
              text: "",
              status: "error",
              clean: false,
            };
          }
          let form = this.address_form.fields;
          let address = Array.from(this.ADMIN_ADDRESSES).filter(
            (item) =>
              item.address.toLowerCase() == this.activeAddress.toLowerCase()
          )?.[0];
          this.openDialog(this.addresses_dialog);
          form.inputs.address.value = address.value;
          form.inputs.street_index.value = address.street_index + "";
          form.inputs.delivery_zone.value = address.delivery_zone + "";
          form.inputs.affilate.value = address.affilate + "";
          form.inputs.mileage.value = address.mileage + "";
          this.clone_address_form = JSON.parse(
            JSON.stringify(this.address_form)
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "selectRequiredProduct") {
        let { products } = this.address_form.fields.inputs;
        if (!products.value.filter((item) => item === settings).length)
          products.value.unshift(settings);
        else
          products.value = products.value.filter((item) => item !== settings);
      }
    },
    async addressValidation(action) {
      try {
        action;
        const form = this.address_form.fields;
        form;
        return true;
      } catch (error) {
        if (error?.title) {
          this.GLOBAL_NOTIFICATIONS(error);
        }
        return false;
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    refreshForm(dialog) {
      // this.address_form = JSON.parse(JSON.stringify(this.refresh_form));
      if (dialog !== undefined) {
        this[dialog].options.visible = false;
      }
    },
    filtreAddresses() {
      let filtredArr = Array.from(this.ADMIN_ADDRESSES)
        .filter((item) =>
          item.address
            .toLowerCase()
            .includes(this.street_search.value.toLowerCase())
        )
        .splice(0, 20);
      if (filtredArr.length) this.addresses = filtredArr;
      else this.addresses = this.ADMIN_ADDRESSES;
    },
  },
  computed: {
    ...mapGetters(["ADMIN_ADDRESSES"]),
    // filtredAddresses() {
    //   let filtredArr = Array.from(this.ADMIN_ADDRESSES)
    //     .filter((item) =>
    //       item.address
    //         .toLowerCase()
    //         .includes(this.street_search.value.toLowerCase())
    //     )
    //     .splice(0, 20);
    //   if (filtredArr.length) return filtredArr;
    //   else return this.ADMIN_ADDRESSES;
    // },
    activeAddress() {
      return this.active_address;
    },
  },
  async mounted() {
    await this.getAddresses();
    this.refresh_form = JSON.parse(JSON.stringify(this.address_form));
    this.active_address = Array.from(this.ADMIN_ADDRESSES)?.[0]?.value;
  },
};
</script>
<style lang="scss" scoped>
.addresses {
  /* .addresses__section */

  &__section {
  }

  /* .addresses__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .addresses__delete */

  &__delete {
  }

  /* .addresses__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .addresses__append */

  &__append {
  }

  /* .addresses__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .addresses__item */

  &__item {
    width: 32%;
    margin-bottom: 12px;
  }

  &__dialog-form {
  }
  /* .addresses__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
    &.hidden {
      display: none;
    }
  }
  /* .addresses__dialog-types */

  &__dialog-types {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  /* .addresses__dialog-radio */

  &__dialog-radio {
    width: 32%;
  }
  /* .addresses__dialog-checkbox */

  &__dialog-checkbox {
    margin-bottom: 24px;
  }

  /* .addresses__dialog-products */

  &__dialog-products {
    display: flex;
    align-items: stretch;
    overflow-x: auto;
  }

  /* .addresses__dialog-product */

  &__dialog-product {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    cursor: pointer;
    padding: 24px;
    transition: 0.3s ease;
    border-radius: 10px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: $unactive-accent;
    }
    &.include {
      background-color: $accent;
    }
  }
}
</style>
<style lang="scss"></style>
